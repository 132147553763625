import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { Link } from 'gatsby'

const NotFoundPage = () => (
  <Layout>
    <SEO title='404: Nicht gefunden' />
    <div className='max-w-6xl mx-auto text-center py-12'>
      <div className='text-6xl font-medium text-brand-blue leading-none my-3'>404:NICHT GEFUNDEN</div>
      <p>Die angeforderte Ressource wurde nicht gefunden.</p>
      <p>Gehe zur <Link to='/' className='font-semibold text-brand-blue transform transition duration-150 ease-in-out hover:text-brand-orange'>Homepage</Link></p>
    </div>
  </Layout>
)

export default NotFoundPage
